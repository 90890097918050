<template>
  <div class="container">
    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-start align-center"
        >
          <v-img
            max-width="100%"
            src="../../assets/sobre_territorio_borba_rompimento_01.png"
          />
          <p style="font-size: 0.5em; text-align: center;">
            O Rio Gualaxo do Norte, que atravessa o território das comunidades
            atingidas de Mariana e Barra Longa, encontra-se contaminado e
            impróprio para consumo. Foto: Jornal A SIRENE, 2021.
          </p>
        </v-col>
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-center align-center justify-md-start"
        >
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Os terrenos da comunidade de Borba localizam-se nas margens do
                Rio Gualaxo e possuíam as áreas produtivas nas extensões de
                baixada, alcançadas e destruídas pela ação da lama.
                Consequentemente, muitas plantações e áreas de pastagem foram
                destruídas, principalmente aquelas localizadas próximas ao curso
                d’água. Assim como em Pedras, o gado atualmente pasta nos
                morros. Criações de galinhas foram levadas e hortas destruídas.
                As áreas próximas ao rio, em sua maioria, ainda possuem lama
                sobre o solo natural, inviabilizando os cultivos e a atividade
                pecuária.
              </p>
              <p>
                As atividades produtivas características de Borba e sua região
                ficaram significativamente prejudicadas pelo rompimento da
                barragem. As famílias não possuem mais
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'conteudos_tematicos_cultura_alimentar' }"
                  >soberania alimentar</router-link
                >
                diante da dificuldade de cultivo dos próprios alimentos, livres
                de agrotóxicos e fertilizantes químicos.
              </p>
              <p>
                Na paisagem são observadas
                <span class="text-pink"
                  >diversas espécies arbóreas secando</span
                >
                e, de acordo com os relatos dos moradores, espécies de pássaros
                já não são mais vistos, além dos peixes que desapareceram.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-space-between align-center">
        <v-col
          cols="12"
          md="7"
          class="d-flex flex-column justify-start align-start"
        >
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Parte dos moradores foi obrigado a deixar suas casas, seus
                terrenos, além das relações de amizade e a ligação com o
                território. Os que permaneceram convivem com a insegurança
                quanto a um novo rompimento, a exemplo da barragem de Germano,
                bem como quanto à incerteza da presença de material tóxico na
                água, no solo e nos produtos consumidos. O isolamento físico
                temporário também causou transtornos para a população, que ficou
                sem acesso enquanto as pontes e estradas eram reconstruídas. Por
                um período, os moradores precisaram utilizar caminhos
                alternativos, mais longos e em estado ruim. Com a perda de
                acesso mesmo que temporário, o escoamento da produção ficou
                prejudicado, assim como a chegada de insumos. Durante esse
                período, a falta de acesso acarretou a perda de produção pela
                falta de manejo das áreas isoladas.
              </p>
              <p>
                A comunidade e a região de modo geral convivem com o
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{
                    name: 'conteudos_tematicos_casas_desterritorializacao',
                  }"
                  >êxodo populacional</router-link
                >, a falta de mão de obra e a ausência de políticas para a
                retomada das atividades e restabelecimento das relações
                comerciais.
              </p>
              <p style="text-align: center;">
                <strong>Autoria: Ana Paula Alves Ferreira<sup>1</sup></strong>
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="d-flex flex-column justify-start align-end"
        >
          <img
            width="100%"
            src="../../assets/sobre_territorio_borba_rompimento_02.png"
          />
          <p style="font-size: 0.5em; text-align: center;">
            Curso d’água (Rio Gualaxo do Norte) atravessando o território de
            Borba. Foto: Vistoria feita pela assessoria técnica da Cáritas,
            2019.
          </p>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p style="margin-bottom: 0;">
              <sup>1</sup> Arquiteta e Urbanista pela USP. Assessora Técnica na
              ATI Mariana.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
export default {
  components: { Card },
  data() {
    return {};
  },
};
</script>

<style scoped>
h6 {
  font-size: 0.8em;
}

section {
  margin-bottom: 3em;
}
.position-rel {
  position: relative;
}

.position-abs {
  position: absolute;
}

.table__section {
  margin-top: 3em;
  margin-bottom: 3em;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
  border-radius: 8px;
}

.inside__card p {
  font-size: 0.5em;
}
</style>
